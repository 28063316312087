var vanilla = module.exports

vanilla.click = function (el, fn) {
	if (el.addEventListener) {
		el.addEventListener('click', fn)
	} else {
		el.onclick = fn
	}
}

vanilla.toggleClass = function (el, className) {
	if (el.classList) {
		el.classList.toggle(className)
	} else {
		var classes = el.className.split(' ')
		var existingIndex = classes.indexOf(className)

		if (existingIndex >= 0) {
			classes.splice(existingIndex, 1)
		} else {
			classes.push(className)
		}
		el.className = classes.join(' ')
	}
}

vanilla.removeClass = function (el, className) {
	if (el.classList) {
		el.classList.remove(className)
	} else {
		el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
	}
}

vanilla.addClass = function (el, className) {
	if (el.classList) {
		el.classList.add(className)
	} else {
		el.className += ' ' + className
	}
}
