const SmoothScroll = require('smooth-scroll')
new SmoothScroll('a[href*="#"]')
const vanilla = require('./vanilla.js')

const header = document.getElementsByClassName('home-nav-mobile')[0]

if (header) {
	vanilla.click(document.getElementsByClassName('sass-burger')[0], function () {
		vanilla.toggleClass(header, 'mobile-nav-expanded')
	})
	vanilla.click(document.getElementsByClassName('home-nav-mobile-list')[0], function () {
		vanilla.toggleClass(header, 'mobile-nav-expanded')
	})
}

if (document.getElementById('minecraft')) {
	getMinecraftStatus()
}

function getMinecraftStatus () {
	const xhr = new XMLHttpRequest()
	xhr.open('GET', document.querySelector('meta[name="mcstatusurl"]').getAttribute('content'))
	xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
	xhr.setRequestHeader('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').getAttribute('content'))
	xhr.onload = function () {
		let response = JSON.parse(xhr.response)

		let icon = document.getElementById('mc_icon')
		let mcstatus = document.getElementById('mc_status')
		let mcplayers = document.getElementById('mc_players')
		let mcdesc = document.getElementById('mc_desc')

		if (response.status !== 200 && response) {
			icon.style.backgroundImage = 'url(' + response.favicon + ')'
			mcstatus.style.color = 'lime'
			mcstatus.innerHTML = 'online (' + response.ping + 'ms)'
			mcplayers.innerHTML = '(' + response.players + '/' + response.max_players + ')'
			mcdesc.innerHTML = response.description.text
		} else {
			mcstatus.style.color = 'red'
			mcstatus.innerHTML = 'offline'
			mcplayers.innerHTML = '(0/50)'
		}
	}
	xhr.send()
}

const ee1 = document.getElementById('staff-2')

if (ee1) {
	vanilla.click(ee1, function () {
		vanilla.toggleClass(document.getElementsByTagName('body')[0], 'easter-egg-1')
	})
}

const btnfurmapadd = document.getElementById('furmap-add')
var boolbtnfurmapadd = false

if (btnfurmapadd) {
	btnfurmapadd.addEventListener('click', function () {
		let popup = document.getElementById('furmap-popup')
		if (boolbtnfurmapadd == false) {
			popup.style.display = 'block'
			boolbtnfurmapadd = true
		} else {
			popup.style.display = 'none'
			boolbtnfurmapadd = false
		}
	})
}